'use strict';

class propostaNewController {
	/* @ngInject */
	constructor(
		$scope,
		$uibModal,
		$q,
		$state,
		$timeout,
		propostaNewApi,
		autoCompleteService,
		formControlService,
		validationFormFields,
		propostaSetupAccountService,
		resgateService,
		breadcrumbHelper,
		feedbackMessages
	) {
		// console.log('propostaNewController');

		this.resgateService = resgateService;
		this.accountDisabled = false;
		this.propostaNewApi = propostaNewApi;
		this.messages = feedbackMessages;
		this.autoComplete = autoCompleteService;
		this.valid = validationFormFields;
		this.$scope = $scope;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.$q = $q;
		this.$timeout = $timeout;
		this.formControl = formControlService;
		this.formField = this.formControl.field;
		this.propostaSetupAccountService = propostaSetupAccountService;
		this.$scope.breadcrumb = this.$scope.$parent.breadcrumb;
		this.tipoProposta = sessionStorage.getItem('tipo_lista');
		this.configureBreadCrumb();

		this.showOutros = false;

		this.apjError = false;

		this.contact = {};
		this.required = {};
		this.validated = {};
		this.$scope.$emit('changeMainShowLoading', false);
		this.formField.formStep = 'proposta.new';

		this.checkOnLoadRequiredFields();

		this.checkIfHasUserInfo();
		this.listenValidationRequest();
		this.listenRequiredRequest();
		this.listenDuplicatedCpfValidationResponse();
		this.setFromProposta(false);
		this.clearParentMessages();

		const propostaType = sessionStorage.getItem('tipo_lista');

		if (propostaType == null || propostaType == undefined) {
			alert('Não é permitido iniciar uma nova proposta sem selecionar o tipo de proposta. Você será redirecionado.');

			this.formControl.reset();
			this.$state.go('proposta.list');

			//https://icatu--homolog.sandbox.my.site.com/caponline/secured#!/proposta/nova
			return;
		}

		//Campo que é enviado na conclusão da proposta para indicar que é originada a partir de um resgate
		if (this.formField.isOrigemResgate == null) {
			this.formField.isOrigemResgate = this.resgateService.getPropostaFromResgate();
		}
		if (this.resgateService.getPropostaFromResgate() === true) {
			this.accountDisabled = true;
			this.formField.account = this.resgateService.getResgateAccount();
			this.formControl.data.resgateValue = this.resgateService.getResgateValue();
		}

		angular.element(function () {
			console.log('page loading completed');

			const cpf_contrate_aqui = sessionStorage.getItem('cpf_contrate_aqui');
			console.log('cpf_contrate_aqui: ', cpf_contrate_aqui);
			sessionStorage.removeItem('cpf_contrate_aqui');

			if (cpf_contrate_aqui) {
				const searchAccountField = angular.element(document.querySelector('#searchAccount_value'));

				console.log('searchAccountField', searchAccountField);

				const meuElemento = $('#searchAccount_value')[0];
				console.log('meuElemento', meuElemento);
				meuElemento.value = '';

				meuElemento.focus();

				simulateTyping(meuElemento, cpf_contrate_aqui, 0);

				function simulateTyping(element, value, i) {
					if (i < value.length) {
						element.value += value.charAt(i);
						element.dispatchEvent(new Event('change'));
						element.dispatchEvent(new Event('keypress'));
						element.dispatchEvent(new Event('keyup'));
						setTimeout(() => {
							i++;
							simulateTyping(element, value, i);
						}, 200);
					}
				}
			} else {
				console.log('nenhum cpf informado no simulador ...');
			}
		});
	}

	searchInit(obj) {}

	validateDuplicatedCpf() {
		//console.log('emitindo duplicatedCpfValidation');
		this.$scope.$emit('duplicatedCpfValidation', this.formField);
	}

	listenDuplicatedCpfValidationResponse() {
		this.$scope.$on('duplicatedCpfValidationResponse', (event, response) => {
			//console.log('escutando duplicatedCpfValidationResponse')
			if (response.length === 0) {
				this.validated.accountError = false;
			} else {
				for (let i = 0; i < response.length; i++) {
					if (this.formField.account != null && response[i] === this.formField.account.cpf) {
						this.messages.showErrorMessage(this.$scope, 'CPF/CNPJ já informado na proposta');
						this.validated.accountError = true;
					} else {
						this.validated.accountError = false;
					}
				}
			}
		});
	}

	configureBreadCrumb() {
		this.$scope.breadcrumb.reset();
		this.$scope.breadcrumb.addMenuItem('Home', '/caponline/secured#!/home', true, false);
		this.$scope.breadcrumb.addMenuItem('Proposta', '/caponline/secured#!/proposta/lista', true, false);
		if (this.tipoProposta == 'C') {
			this.$scope.breadcrumb.addMenuItem('Nova Proposta Capitalização', '#', false, true);
		} else if (this.tipoProposta == 'D') {
			this.$scope.breadcrumb.addMenuItem('Nova Proposta Dupla Garantia', '#', false, true);
		} else {
			this.$scope.breadcrumb.addMenuItem('Nova Proposta', '#', false, true);
		}
		console.log('TIPO DE PROPOSTA ', sessionStorage.getItem('tipo_lista'));
		this.formField.proposalType = sessionStorage.getItem('tipo_lista');
	}

	isPropostaDuplaGarantia() {
		const propostaType = sessionStorage.getItem('tipo_lista');
		if (propostaType == 'D') {
			return true;
		} else {
			return false;
		}
	}

	checkOnLoadRequiredFields() {
		// console.log('checkOnLoadRequiredFields', this.formField);

		if (this.propostaSetupAccountService.getFromProposta()) {
			this.formField.stepStatusPropostaNew = 'Não Iniciado';
		}

		if (!this.isFormPristine() && this.formRequired()) {
			return;
		}

		this.formField.stepStatusPropostaNew = 'Incompleto';
	}

	isAccountDisabled() {
		return (this.formField.status && this.formField.status.toLowerCase() != 'em andamento') || this.accountDisabled;
	}

	isFormPristine() {
		// console.log('isFormPristine', this.formField.stepStatusPropostaNew === 'Não Iniciado');

		return this.formField.stepStatusPropostaNew === 'Não Iniciado';
	}

	clearParentMessages() {
		this.$scope.$emit('clearMessages');
	}

	checkIfHasUserInfo() {
		const userInfo = this.propostaSetupAccountService.getNewAccountInfo();
		// console.log(userInfo);
		if (userInfo) {
			this.formField.account = userInfo;
			//this.resourceOutrosSelected();
		}
	}

	setFromProposta(value) {
		this.propostaSetupAccountService.setFromProposta(value);
	}

	searchAccount(searchString) {
		console.log('searchAccount', searchString, this);

		const propostaNew = this.$parent.propostaNew;

		console.log('propostaNew', propostaNew);

		const retornoSearchAccount = propostaNew.propostaNewApi.searchAccount(searchString);

		console.log('retornoSearchAccount:', retornoSearchAccount);

		return retornoSearchAccount;
	}

	searchProfession(searchString) {
		// console.log('searchProfession', this);

		const propostaNew = this.$parent.propostaNew;
		const isPersonAccount = propostaNew.formField.account.isPersonAccount;

		return propostaNew.propostaNewApi.searchProfession(searchString, isPersonAccount);
	}

	searchNoResultText() {
		if (!this.hasAccount()) {
			return;
		}

		const professionText = 'Nenhuma profissão encontrada';
		const activityText = 'Nenhuma Atividade Principal encontrada';

		return this.formField.account.isPersonAccount ? professionText : activityText;
	}

	searchAccountFocusOut() {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			// console.log('timeout FocusOut', this.formField.account);

			if (!this.autoComplete.hasValidInput(this.formField.account)) {
				this.autoComplete.clearInput(this.$scope, 'searchAccount');
				this.autoComplete.clearInput(this.$scope, 'searchProfession');
				this.autoComplete.resetSelected(this.formField, 'profession');
			}
		}, 100);
		this.validateDuplicatedCpf();
	}

	modalHandle() {
		this.searchAccountFocusOut();
		const parentElem = angular.element(document.querySelector('.col-lg-4'));
		const propostaType = sessionStorage.getItem('tipo_lista');
		if (propostaType == 'C') {
			this.$uibModal.open({
				template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromProposta(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div><div class="col-lg-6"><a class="box-modal pull-left" href="" ui-sref="new-juridical-account" ng-click="modalController.setFromProposta(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePJAccountPath}}"/><p>Pessoa Jurídica</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
				controller: 'accountTypeModalController',
				controllerAs: 'modalController',
				appendTo: parentElem
			});
		} else {
			this.$uibModal.open({
				template: '<div class="modal-content acc"><div class="modal-body"><h4 class="modal-title text-center">Que tipo de cadastro quer criar?</h4><div class="col-lg-6"><a class="box-modal pull-right" href="" ui-sref="new-account" ng-click="modalController.setFromProposta(true); modalController.closeModal()"><img class="ico-type" src="{{modalController.imagePFAccountPath}}"/><p>Pessoa Física</p></a></div></div><div class="modal-footer"><button class="btn btn-link cancel" type="button" ng-click="modalController.closeModal()">Cancelar</button></div></div>',
				controller: 'accountTypeModalController',
				controllerAs: 'modalController',
				appendTo: parentElem
			});
		}
	}

	searchProfessionFocusOut() {
		// console.log('searchProfessionFocusOut', this);

		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		this.$timeout(() => {
			const hasValidInput = this.autoComplete.hasValidInput(this.formField.profession);

			if (!hasValidInput) {
				this.autoComplete.clearInput(this.$scope, 'searchProfession');
			}
		}, 100);
	}

	searchAccountSelect(item) {
		// console.log('searchAccountSelect', item);

		if (!item) {
			return;
		}

		const propostaNew = this.$parent.propostaNew;
		const hasAction = !!item.originalObject.action;

		if (hasAction) {
			const action = item.originalObject.action;

			propostaNew[action](item);

			return;
		}

		//Aplica valor para contas sem o campo
		propostaNew.formField.account = item.originalObject;

		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'profession');
		propostaNew.requireAccount();

		propostaNew.messages.cleanMessages(propostaNew.$scope);
		propostaNew.validateDuplicatedCpf();

		const isPersonAccount = propostaNew.formField.account.isPersonAccount;
		const cpf = propostaNew.formField.account.cpf;
	}

	searchProfessionSelect(item) {
		// console.log('searchProfessionSelect', item);

		if (!item) {
			return;
		}

		const propostaNew = this.$parent.propostaNew;

		propostaNew.formField.profession = item.originalObject;

		propostaNew.requireProfession();
	}

	isPEP() {
		// console.log('isPEP');
		return this.hasAccount() && this.formField.account.isPEP;
	}

	isPJ() {
		// console.log('isPJ', this.hasAccount() && !this.formField.account.isPersonAccount);
		// console.log('hasAccount ', this.hasAccount());
		// console.log('formField', this.formField.account);
		return this.hasAccount() && this.formField.account.isPersonAccount === false;
	}

	isPF() {
		// console.log('isPJ', this.hasAccount() && !this.formField.account.isPersonAccount);
		// console.log('hasAccount ', this.hasAccount());
		// console.log('formField', this.formField.account);
		return this.hasAccount() && this.formField.account.isPersonAccount === true;
	}

	searchAccountChanged() {
		const propostaNew = this.$parent.propostaNew;
		// console.log('searchAccountChanged', propostaNew.formField.account, this);

		const valor = $('#searchAccount input').val();
		if (valor === '') propostaNew.messages.cleanMessages(propostaNew.$scope);

		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'account');
		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'profession');
		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'declarationCode');
		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'resourceOrigin');
		propostaNew.autoComplete.clearInput(propostaNew.$scope, 'searchProfession');

		// Ao trocar o Account, limpa campos da aba Pagamento
		propostaNew.formField.isAccountOwnerDebitSameProspect = false;
		propostaNew.formField.accountOwnerCpfCnpjDebit = '';
		propostaNew.formField.accountOwnerNameDebit = '';
		propostaNew.formField.listApjs = [];

		propostaNew.requireDeclarationCode();
		propostaNew.requireProfession();
	}

	searchProfessionChanged() {
		// console.log('searchProfessionChanged', this);
		const propostaNew = this.$parent.propostaNew;
		propostaNew.autoComplete.resetSelected(propostaNew.formField, 'profession');
	}

	newAccount(...parameters) {
		// console.log('newAccount', ...parameters, this);
		// this.autoComplete.clearInput();
	}

	informIncome() {
		// console.log('informIncome', this.formField.declarationCode);
		return this.formField.declarationCode === 'Valor informado pelo cliente';
	}

	hasAccount() {
		return !!this.formField.account;
	}

	hasAccountEmail() {
		/*if (this.formField.account.email == '') {
			return false;
		} else {
			return true;
		}*/
		return !!this.formField.account.email;
	}

	hasProfession() {
		return !!this.formField.profession;
	}

	hasDeclarationCode() {
		// console.log('hasDeclarationCode', !!this.formField.declarationCode, this.formField.declarationCode);

		return !!this.formField.declarationCode;
	}

	hasIncomeValue() {
		if (this.formField.declarationCode === 'Cliente se recusou a informar') {
			return true;
		}

		return !!this.formField.incomeValue;
	}

	hasResourceOrigin() {
		console.log('---> hasResourceOrigin: ', !!this.formField.resourceOrigin);

		return !!this.formField.resourceOrigin;
	}

	hasContacts() {
		//console.log('hasContacts');

		return this.hasAccount() && this.formField.listApjs && this.formField.listApjs.length;
	}

	addContact() {
		// console.log('addContact', this.contact);

		if (this.formField.status && this.formField.status != 'Em andamento') {
			return;
		}

		this.isValidContact()
			.then(() => {
				// console.log('addContact success', this.contact);

				const contactModel = {
					FirstName: this.contact.name.split(/ (.+)/)[0],
					LastName: this.contact.name.split(/ (.+)/)[1],
					cpf: this.contact.cpf,
					resourceOrigin: this.contact.resourceOrigin || null
				};

				// console.log('contactModel', contactModel);

				this.formField.listApjs.push(contactModel);

				// console.log('listApjs', this.formField.listApjs);

				this.resetContactModel();
			})
			.catch(() => {
				// console.log('addContact error', this.contact);
			});
	}

	isValidContact() {
		// console.log('isValidContact');

		const deferred = this.$q.defer();

		const isValidCpf = this.valid.isValidCpf(this.contact.cpf);
		// const isValidCnpj = this.valid.isValidCnpj(this.contact.cpf);

		this.contact.nameError = !this.valid.isValidName(this.contact.name);
		// this.contact.cpfError = !isValidCpf && !isValidCnpj;
		this.contact.cpfError = !isValidCpf;

		if (this.contact.nameError || this.contact.cpfError) {
			deferred.reject();
			return deferred.promise;
		}

		// if (isValidCnpj) {

		// 	deferred.resolve();
		// 	return deferred.promise;
		// }

		if (this.contactHasPEP()) {
			if (this.requireContactResourceOrigin()) {
				deferred.resolve();
			} else {
				deferred.reject();
			}

			return deferred.promise;
		}

		this.getContactPEP(this.contact.cpf).then((data) => {
			this.contact.isPEP = data.isPEP;

			if (this.contactHasPEP()) {
				deferred.reject();
			} else {
				deferred.resolve();
			}

			this.loaderContactPEP = false;
		});

		return deferred.promise;
	}

	requireContactResourceOrigin() {
		if (this.contactHasResourceOrigin()) {
			this.contact.resourceOriginError = false;
			return true;
		}

		this.contact.resourceOriginError = true;
		return false;
	}

	contactHasPEP() {
		return this.contact.isPEP === true;
	}

	contactHasResourceOrigin() {
		return !!this.contact.resourceOrigin;
	}

	getContactPEP(cpf) {
		// console.log('getContactPEP');

		this.loaderContactPEP = true;

		return this.propostaNewApi.isPEP(cpf);
	}

	resetContactModel() {
		// console.log('resetContactModel');

		this.contact.name = null;
		this.contact.cpf = null;
		this.contact.resourceOrigin = null;
		this.contact.isPEP = null;
	}

	removeContact(itemIndex) {
		// console.log('removeContact', itemIndex);

		if (this.formField.status && this.formField.status != 'Em andamento') {
			return;
		}

		this.formField.listApjs.splice(itemIndex, 1);
	}

	listenValidationRequest() {
		// console.log('listenValidationRequest');

		this.$scope.$on('stepValidate', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepValidateResponse', this.formValidation());
		});
	}

	listenRequiredRequest() {
		// console.log('listenRequiredRequest');

		this.$scope.$on('stepRequired', () => {
			// console.log('listened stepValidate');

			this.$scope.$emit('stepRequiredResponse', this.formRequired());
			console.log('Step Required - Tela Proposta New - ', this.formRequired());
		});
	}

	formRequired() {
		// console.log('formRequired');

		let requiredFieldsOk = true;

		this.resetRequiredErrors();

		if (!this.requireAccount()) {
			console.log('require account com problema');
			requiredFieldsOk = false;
		}

		if (!this.requireRetirementType()) {
			console.log('require retirement com problema');
			requiredFieldsOk = false;
		}

		if (!this.requireAccountEmail()) {
			console.log('require account email com problema');
			requiredFieldsOk = false;
		}

		if (!this.requireProfession()) {
			console.log('require profession com problema');
			requiredFieldsOk = false;
		}

		if (!this.requireDeclarationCode()) {
			console.log('require declaracao de renda com problema');
			requiredFieldsOk = false;
		}

		if (!this.requireBirthCountry()) {
			requiredFieldsOk = false;
		}

		if (!this.requireNationality()) {
			requiredFieldsOk = false;
		}

		if (!this.requireSecondNationality()) {
			if (this.formField.account.secNac == 'true') {
				requiredFieldsOk = false;
			}
		}

		/*
		if (!this.requireIncomeValue()) {
			console.log('require income value com problema')
			requiredFieldsOk = false;
		}*/

		if (this.formField.differentFinancialResponsible == 'false') {
			if (!this.requireResourceOrigin()) {
				console.log('require resource origin com problema');
				requiredFieldsOk = false;
			}

		}

		if (!this.requireResourceOriginOutros()) {
			console.log('require resource origin - outros com problema');
			requiredFieldsOk = false;
		}

		if (requiredFieldsOk) {
			this.formField.stepStatusPropostaNew = 'Concluido';
		} else {
			this.formField.stepStatusPropostaNew = 'Incompleto';
		}

		return requiredFieldsOk;
	}

	requireNationality() {
		if (this.hasAccount()) {
			if (this.isPF()) {
				if (!!this.formField.account.nationality) {
					console.log('Titular Nacionalidade OK');
					this.required.nationalityError = false;
					return true;
				} else {
					console.log('Nacionalidade do titular obrigatório');
					this.required.nationalityError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireSecondNationality() {
		if (this.hasAccount()) {
			if (this.isPF()) {
				if (this.formField.account.secNac == 'true') {
					if (!!this.formField.account.secondNationality) {
						console.log('Titular Segunda nacionalidade OK');
						this.required.secondNationalityError = false;
						return true;
					} else {
						console.log('Titular Segunda nacionalidade do Subscritor obrigatório');
						this.required.secondNationalityError = true;
						return false;
					}
				} else {
					this.required.secondNationalityError = false;
					return true;
				}
			}
		}
		return true;
	}

	requireBirthCountry() {
		if (this.hasAccount()) {
			if (this.isPF()) {
				if (!!this.formField.account.birthCountry) {
					console.log('Titular Pais nascimento OK');
					this.required.birthCountryError = false;
					return true;
				} else {
					console.log('Titular Pais nascimento do Subscritor obrigatório');
					this.required.birthCountryError = true;
					return false;
				}
			}
		}
		return true;
	}

	requireAccount() {
		if (this.hasAccount()) {
			this.required.accountError = false;
			return true;
		}

		this.required.accountError = true;
		return false;
	}

	requireProfession() {
		if (this.hasProfession()) {
			this.required.professionError = false;
			return true;
		}

		this.required.professionError = true;
		return false;
	}

	requireAccountEmail() {
		if (this.hasAccount()) {
			if (this.hasAccountEmail()) {
				this.required.accountEmailError = false;
				return true;
			}
			this.required.accountEmailError = true;
			return false;
		}
	}

	requireRetirementType() {
		if (this.hasResourceOrigin()) {
			if (this.formField.resourceOrigin == 'Aposentadoria' && this.hasRetirementType()) {
				this.required.retirementTypeError = false;
				console.log('Liberado erro retirement1');
				return true;
			} else if (this.formField.resourceOrigin == 'Aposentadoria' && !this.hasRetirementType()) {
				this.required.retirementTypeError = true;
				console.log('Erro retirement');
				return false;
			} else {
				this.required.retirementTypeError = false;
				console.log('Liberado erro retirement2');
				return true;
			}
		} else {
			this.required.retirementTypeError = false;
			console.log('Liberado erro retirement3');
			return true;
		}
	}

	hasRetirementType() {
		return !!this.formField.retirementType;
	}

	requireDeclarationCode() {
		// console.log('requireDeclarationCode');

		if (this.hasDeclarationCode()) {
			this.required.declarationCodeError = false;
			return true;
		}

		this.required.declarationCodeError = true;
		return false;
	}

	searchNacionalidade(searchString) {
		console.log('searchNacionalidade | ', searchString, ' | this : ', this);
		return this.$parent.propostaNew.propostaNewApi.searchNacionalidade(searchString);
	}

	searchNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const propostaNew = this.$parent.propostaNew;
		propostaNew.autoComplete.resetSelected(propostaNew.formField.account, 'nationality');
	}

	searchNacionalidadeSelect(item) {
		console.log('searchNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const propostaNew = this.$parent.propostaNew;

		propostaNew.formField.account.nationality = item.originalObject;
	}

	searchSegundaNacionalidadeChanged() {
		console.log('searchNacionalidadeChanged', ' | this : ', this);

		const propostaNew = this.$parent.propostaNew;
		propostaNew.autoComplete.resetSelected(propostaNew.formField.account, 'secondNationality');
	}

	searchSegundaNacionalidadeSelect(item) {
		console.log('searchSegundaNacionalidadeSelect', ' | this : ', this);

		if (!item) {
			return;
		}

		const propostaNew = this.$parent.propostaNew;

		propostaNew.formField.account.secondNationality = item.originalObject;
	}

	requireIncomeValue() {
		// console.log('requireIncomeValue');

		if (this.hasIncomeValue()) {
			this.required.incomeValueError = false;
			return true;
		}

		this.required.incomeValueError = true;
		return false;
	}

	requireResourceOrigin() {
		console.log('requireResourceOrigin');

		if (this.hasResourceOrigin()) {
			console.log('- possui origem de recurso');
			this.required.resourceOriginError = false;
			return true;
		}

		console.log('- NAO possui origem de recurso');
		this.required.resourceOriginError = true;
		return false;
	}

	requireResourceOriginOutros() {
		console.log('this.isPJ(): ', this.isPJ());
		console.log('resourceOrigin: ', this.formField.resourceOrigin);
		console.log('resourceOriginOutros: ', this.formField.resourceOriginOutros);
		console.log('resourceOriginOutros: ', this.formField.resourceOriginOutros);
		console.log('!!this.formField.resourceOriginOutros): ', !!this.formField.resourceOriginOutros);

		if (this.isPF()) {
			if (this.formField.resourceOrigin == 'Outros') {
				//this.resourceOutrosSelected();

				if (this.formField.resourceOriginOutros == null || this.formField.resourceOriginOutros == '') {
					this.required.resourceOriginOutrosError = true;
					return false;
				}
			}

			this.required.resourceOriginOutrosError = false;
			return true;
		} else {
			this.required.resourceOriginOutrosError = false;
			return true;
		}
	}

	requireApj() {
		if (this.hasApj()) {
			return true;
		}

		return false;
	}

	resetRequiredErrors() {
		// console.log('resetRequiredErrors');

		this.required.accountError = false;
		this.required.accountEmailError = false;
		this.required.professionError = false;
		this.required.declarationCodeError = false;
		this.required.incomeValue = false;
		this.required.resourceOriginError = false;
		this.required.resourceOriginOutrosError = false;
		this.required.retirementTypeError = false;
	}

	resetValidErrors() {
		this.validated.apjError = false;
		this.validated.accountError = false;
	}

	isValidAccount() {
		this.validateDuplicatedCpf();
		if (!this.hasAccount() || !this.validated.accountError) {
			return true;
		}

		return false;
	}

	hasApj() {
		console.log('validateAPJ');
		let isOkApj = false;

		console.log(this.formControl.validateApj());

		if (!this.formControl.validateApj()) {
			this.apjError = true;
			isOkApj = true;
		} else {
			this.apjError = false;
			isOkApj = false;
		}
		return isOkApj;
	}

	isValidApj() {
		console.log('isValidAPJ');
		if (this.validateApj()) return true;
		return false;
	}

	formValidation() {
		// console.log('formValidation');
		let validFieldsOk = true;

		this.resetValidErrors();

		if (!this.isValidAccount()) {
			validFieldsOk = false;
		}

		console.log('validFieldsOk ? ', validFieldsOk);

		return validFieldsOk;
	}

	hasAccountError() {
		return this.required.accountError || this.validated.accountError;
	}

	isActivityDisabled() {
		return (this.formField.status && this.formField.status !== 'Em andamento') || !this.hasAccount();
	}

	searchPaisFocusOut(context) {
		// @todo workaround https://github.com/ghiden/angucomplete-alt/issues/106
		if (context == 'birth') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.account.birthCountry);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchPais');
				}
			}, 100);
		} else if (context == 'nac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.account.nationality);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchNacionalidade');
				}
			}, 100);
		} else if (context == 'secNac') {
			this.$timeout(() => {
				const hasValidInput = this.autoComplete.hasValidInput(this.formField.account.secondNationality);

				if (!hasValidInput) {
					this.autoComplete.clearInput(this.$scope, 'searchSegundaNacionalidade');
				}
			}, 100);
		}
	}

	searchPaisChangedBirth() {
		const propostaNew = this.$parent.propostaNew;
		console.log('Entrou no reset birthCountry');

		propostaNew.autoComplete.resetSelected(propostaNew.formField.account, 'birthCountry');
	}

	searchPaisChangedNac() {
		const propostaNew = this.$parent.propostaNew;

		console.log('Entrou no reset nat');
		propostaNew.autoComplete.resetSelected(propostaNew.formField.account, 'nationality');
	}

	searchPaisChangedSecNac() {
		const propostaNew = this.$parent.propostaNew;

		console.log('Entrou no reset secNac');
		propostaNew.autoComplete.resetSelected(propostaNew.formField.account, 'secondNationality');
	}

	searchPais(searchString) {
		const propostaNew = this.$parent.propostaNew;

		return propostaNew.propostaNewApi.searchPais(searchString);
	}

	searchPaisSelectBirth(item) {
		const propostaNew = this.$parent.propostaNew;

		if (!item) {
			return;
		}

		propostaNew.formField.account.birthCountry = item.originalObject;

		console.log('FORMS ATUAL: ', propostaNew.formField);
		console.log('ITEM: ', item);
	}

	searchPaisSelectNac(item) {
		const propostaNew = this.$parent.propostaNew;

		if (!item) {
			return;
		}

		propostaNew.formField.account.nationality = item.originalObject;

		console.log('FORMS ATUAL: ', propostaNew.formField);
		console.log('ITEM: ', item);
	}

	searchPaisSelectSecNac(item) {
		const propostaNew = this.$parent.propostaNew;

		if (!item) {
			return;
		}

		propostaNew.formField.account.secondNationality = item.originalObject;

		console.log('FORMS ATUAL: ', propostaNew.formField);
		console.log('ITEM: ', item);
	}

	searchPaisNoResultText() {
		const resultText = 'Nenhum país encontrado.';
		return resultText;
	}

	searchNacionalidadeNoResultText() {
		const resultText = 'Nenhuma nacionalidade encontrada.';
		return resultText;
	}

}

module.exports = propostaNewController;
